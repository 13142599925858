import config from '@/config';
import { SkyHubUser } from '@/models/SkyHubUser';
import { AuthClient } from '@/services/auth';
import { RootState } from '@/store/types';
import { name as userModuleName } from '@/store/user';
import { MutationKeys as userMutationKeys } from '@/store/user/mutations';
import firebase from 'firebase/app';
import 'firebase/auth';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { Store } from 'vuex';

class AuthRouteHandler {
  private static store: Store<RootState>;

  static async initialize(
    store: Store<RootState>,
    router: VueRouter
  ): Promise<void> {
    this.store = store;
    const implementation = config.environment;

    if (implementation === 'firebase') {
      firebase.auth().onAuthStateChanged(async (user) => {
        await AuthRouteHandler.onStateChange(router, user);
      });
    } else {
      await AuthClient.getInstance().signIn();
    }

    router.beforeEach((to, from, next) => {
      AuthRouteHandler.onRouterEntry(to, next);
    });
  }

  /*
    Auth state changed handler. This gets triggered when there is a change to the currently logged in firebase user
    Login, logout, signup etc.
  */
  static async onStateChange(router: VueRouter, user: firebase.User | null) {
    try {
      // Logout
      if (!user) {
        await this.store.dispatch('userModule/logout');
        return;
      } else {
        await this.store.dispatch('userModule/login', user);
        AuthRouteHandler.loginRedirect(router);
      }
      // Set all authorization headers for API
      const uid = await user.getIdToken();
      this.store.commit(
        `${userModuleName}/${userMutationKeys.SET_FIREBASE_ID_TOKEN}`,
        uid
      );

      // Redirect user if on the login/signup page and there is a redirect query param
      const currentRoute = router.currentRoute;
      const redirect = currentRoute.query.redirect
        ? String(currentRoute.query.redirect)
        : null;
      if (
        (currentRoute.name === 'login' || currentRoute.name === 'signup') &&
        redirect
      ) {
        delete currentRoute.query.redirect; // Don't need this hanging around
        const newRoute = {
          path: redirect,
          query: currentRoute.query
        };
        router.push(newRoute);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  /*
    Router middleware. Guard each route depending on the route configuration in router.ts.
    Ensure the currently signed in user has the correct role to see the route.
  */
  static onRouterEntry(to: Route, next: NavigationGuardNext) {
    try {
      const currentUser = this.store.getters['userModule/currentUser'];
      const isLoggedIn = this.store.getters['userModule/isLoggedIn'];
      const isEmailVerified = this.store.getters['userModule/isEmailVerified'];
      const requiresRoles =
        to.meta && to.meta.roles && to.meta.roles.length > 0 ? true : false;
      const requiresAuth = to.matched.some(
        (record) => record.meta.requiresAuth
      );
      //TODO SKYHUB-875 REFACTOR ME
      if (
        isLoggedIn &&
        !isEmailVerified &&
        to.name !== 'verification' &&
        to.name !== 'login'
      ) {
        next({
          name: 'verification'
        });
      } else if (requiresAuth && !isLoggedIn) {
        next({
          name: 'login',
          query: { redirect: to.path, ...to.query }
        });
      } else if (
        requiresRoles &&
        !this.hasValidRole(currentUser, to.meta?.roles)
      ) {
        next({
          name: 'home'
        });
      } else {
        if (to.name === 'home' && config.skynode.isEnabled) {
          next({
            name: 'skynode.edge.device'
          });
        } else {
          next();
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  static hasValidRole(
    currentUser: SkyHubUser | null,
    requiredRoles: number[]
  ): boolean {
    if (!requiredRoles || !requiredRoles.length) {
      return true;
    }
    if (!currentUser || currentUser.role == null) {
      return false;
    }
    if (requiredRoles.indexOf(currentUser.role) > -1) {
      return true;
    }
    return false;
  }

  static loginRedirect(router: VueRouter) {
    if (router.currentRoute.name === 'login') {
      router.push('home');
    }
  }
}

export default AuthRouteHandler;
