import firebase from 'firebase';

interface IAuthClient {
  signIn(email?: string, pass?: string): Promise<void>;
  signOut(): Promise<void>;
  isEmailVerified(): Promise<boolean>;
  sendPasswordResetEmail(email?: string): Promise<void>;
  sendEmailVerification(): Promise<void>;
  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.User | null>;
}

class AuthClient {
  private static _instance: IAuthClient;

  public static initialize(instance: IAuthClient) {
    this._instance = instance;
  }

  public static getInstance() {
    if (!AuthClient._instance) {
      throw new Error('AuthClient not initialized');
    }
    return AuthClient._instance;
  }
}

export { AuthClient, IAuthClient };
