import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faCheck,
  faChevronDown,
  faCloudUploadAlt,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faFan,
  faFile,
  faListUl,
  faLongArrowAltDown,
  faPause,
  faPen,
  faPlay,
  faPlus,
  faRocket,
  faSortDown,
  faSortUp,
  faSpinner,
  faSyncAlt,
  faTimes,
  faUser,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import App from './App.vue';
import AuthRouteHandler from './router/AuthRouteHandler';
import { createRouter as createSkyHubRouter } from './router/skyhub';
import createSkyHubStore from './store';
import VueCookies from 'vue-cookies';

library.add(
  faEnvelope,
  faEllipsisV,
  faPen,
  faUser,
  faListUl,
  faDownload,
  faPlus,
  faAngleUp,
  faTimes,
  faCloudUploadAlt,
  faChevronDown,
  faCheck,
  faEdit,
  faArrowDown,
  faArrowUp,
  faRocket,
  faFan,
  faWrench,
  faFile,
  faArrowLeft,
  faLongArrowAltDown,
  faSyncAlt,
  faSortDown,
  faSortUp,
  faSpinner,
  faPause,
  faPlay
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
// Adding a comment to trigger a change in admin/webapp

const store = createSkyHubStore();
const router = createSkyHubRouter(store);
Vue.config.productionTip = false;

AuthRouteHandler.initialize(store, router);

Vue.use(VueCookies);
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

// trigger for water
