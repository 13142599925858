import { SkyNodeApi } from '@/api//SkyNodeApi';
import config from '@/config';
import { IPackage } from '@/models/packages/Package';
import {
  CreatePackageVersionDTO,
  IPackageVersion,
  PackageVersion
} from '@/models/packages/PackageVersion';
import { IDownloadUrl } from '@/models/presignedUrls/DownloadUrl';
import { IUploadUrl } from '@/models/presignedUrls/UploadUrl';
import { SkyHubUser } from '@/models/SkyHubUser';
import {
  ISkyNodeClient,
  UpdateSkynodeAllowDto
} from '@/models/skynode/SkyNodeClient';
import { ISkyNodeClientPackage } from '@/models/skynode/SkyNodeClientPackage';

enum URIs {
  package = 'package',
  skynode = 'skynode',
  user = 'user'
}

enum AdminUri {
  user = 'admin/user'
}

class AdminBff {
  constructor(skyNodeApi: SkyNodeApi) {
    this.skyNodeApi = skyNodeApi;
  }
  private skyNodeApi: SkyNodeApi;

  public async updateSkyHubUser(user: SkyHubUser): Promise<void> {
    await this.skyNodeApi.patch(`${AdminUri.user}/${user.uid}`, user);
  }

  public async getAllSkyHubUsers(): Promise<SkyHubUser[]> {
    const response = await this.skyNodeApi.get(AdminUri.user);
    return [...response.data].map((user) => {
      return SkyHubUser.fromJson(user);
    });
  }
}

class Bff {
  private skyNodeApi: SkyNodeApi;
  public admin: AdminBff;

  constructor(skyNodeApi: SkyNodeApi) {
    this.skyNodeApi = skyNodeApi;
    this.admin = new AdminBff(skyNodeApi);
  }

  public async updateSkynodeAllow(
    updateDto: UpdateSkynodeAllowDto
  ): Promise<void> {
    await this.skyNodeApi.patch(`${URIs.skynode}/${updateDto.id}`, updateDto);
  }

  public async getAllSkynodeClientPackages(): Promise<IPackage[]> {
    const response = await this.skyNodeApi.get(`${URIs.package}`);
    return response.data as IPackage[];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapPackageVersion(version: any) {
    if (version) {
      const createdDate = new Date(Date.parse(version.createdDate));
      const updatedDate = new Date(Date.parse(version.updatedDate));
      const packageVersionParams: IPackageVersion = {
        ...version,
        createdDate,
        updatedDate
      };
      return new PackageVersion(packageVersionParams);
    }
    return null;
  }

  public async getSkynodeClientPackageById(
    id: number
  ): Promise<IPackage | undefined> {
    const response = await this.skyNodeApi.get(`${URIs.package}/${id}`);
    const pack = response.data;
    return {
      ...pack,
      latestVersion: this.mapPackageVersion(pack.latestVersion),
      versions: pack.versions.map((version: IPackageVersion) => {
        return this.mapPackageVersion(version);
      })
    };
  }

  public async createSkyNodePackageVersionById(
    packageId: number,
    version: CreatePackageVersionDTO,
    isLatest = false
  ): Promise<IPackageVersion | undefined> {
    const args = {
      params: {
        isLatest
      }
    };
    const response = await this.skyNodeApi.post(
      `${URIs.package}/${packageId}/version`,
      version,
      { params: args }
    );
    return response.data;
  }

  public async createSkyNodePackage(pack: IPackage) {
    return this.skyNodeApi.post(`${URIs.package}`, pack);
  }

  public async updateSkyNodePackageById(
    packageId: number,
    pack: Record<string, unknown>
  ) {
    return this.skyNodeApi.patch(`${URIs.package}/${packageId}`, pack);
  }

  public async updateSkyNodePackageVersionById(
    packageId: number,
    versionId: number,
    version: Record<string, unknown>,
    isLatest = false
  ) {
    const args = {
      params: {
        isLatest
      }
    };
    return this.skyNodeApi.patch(
      `${URIs.package}/${packageId}/version/${versionId}`,
      version,
      { params: args }
    );
  }

  public async getSkyHubUserById(id: string): Promise<SkyHubUser> {
    const response = await this.skyNodeApi.get(`${URIs.user}/${id}`);
    return response.data;
  }

  public async createSkyHubUser(user: SkyHubUser): Promise<SkyHubUser> {
    const response = await this.skyNodeApi.post(URIs.user, user);
    return response.data;
  }

  public async updateSkyHubUser(user: SkyHubUser): Promise<void> {
    await this.skyNodeApi.patch(`${URIs.user}/${user.uid}`, user);
  }

  public async getPresignedUploadUrl(): Promise<IUploadUrl> {
    const response = await this.skyNodeApi.get('storage/upload/url');
    return response.data;
  }

  public async getPresignedDownloadUrl(
    packageId: number,
    versionId: number
  ): Promise<IDownloadUrl> {
    const response = await this.skyNodeApi.get(
      `/storage/download/url/package/${packageId}/version/${versionId}`
    );

    const downloadUrlObj: IDownloadUrl = response.data;
    return downloadUrlObj;
  }

  public async uploadObject(
    url: string,
    file: File,
    progressHandler: (progressEvent: ProgressEvent) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<Record<string, any>> {
    const implementation = config.environment;

    let response;
    if (implementation === 'firebase') {
      response = await this.skyNodeApi.uploadToGoogle(
        progressHandler,
        url,
        file
      );
    } else {
      response = await this.skyNodeApi.uploadToPlatformOne(
        progressHandler,
        url,
        file
      );
    }

    return response.headers;
  }

  public async getAllSkyNodes() {
    const response = await this.skyNodeApi.get(URIs.skynode);
    return response.data;
  }

  public async getSkyNodeById(
    clientId: number
  ): Promise<ISkyNodeClient | undefined> {
    const response = await this.skyNodeApi.get(`${URIs.skynode}/${clientId}`);
    return response.data;
  }

  public async getPackageVersionsForPackages(
    packages: ISkyNodeClientPackage[]
  ): Promise<IPackageVersion[] | undefined> {
    const response = await Promise.all(
      packages.map((pack) =>
        this.skyNodeApi.get(
          `${URIs.package}/${pack.id}/version/${pack.packageVersionId}`
        )
      )
    );
    return response.map((res) => res.data) as IPackageVersion[];
  }
}
export default Bff;
