import Bff from '@/api/Bff';
import { IPackage } from '@/models/packages/Package';
import { CreatePackageVersionDTO } from '@/models/packages/PackageVersion';
import { ActionTree, Commit } from 'vuex';
import { RootState } from '../../types';
import { PackageState } from './types';

class PackageActionKeys {
  public static readonly FETCH_ALL = 'fetchAll';
  public static readonly FETCH_BY_ID = 'fetchById';
  public static readonly UPDATE_PACKAGE_DETAILS = 'updatePackageDetails';
  public static readonly CREATE_PACKAGE = 'createPackage';
  public static readonly CREATE_PACKAGE_VERSION = 'createPackageVersion';
  public static readonly UPDATE_PACKAGE_VERSION = 'updatePackageVersion';
  public static readonly FETCH_PRESIGNED_UPLOAD_URL = 'fetchPresignedUploadUrl';
  public static readonly FETCH_PRESIGNED_DOWNLOAD_URL =
    'fetchPresignedDownloadUrl';
  public static readonly UPLOAD_OBJECT_TO_FILE_STORAGE =
    'uploadObjectToFileStorage';
}

function createActions(bff: Bff): ActionTree<PackageState, RootState> {
  return {
    async [PackageActionKeys.FETCH_ALL]({
      commit
    }: {
      commit: Commit;
    }): Promise<IPackage[]> {
      const data = await bff.getAllSkynodeClientPackages();
      commit('SET_ALL_PACKAGES', data);
      return data;
    },
    async [PackageActionKeys.FETCH_BY_ID](
      { commit }: { commit: Commit },
      id: number
    ): Promise<IPackage | undefined> {
      try {
        const data = await bff.getSkynodeClientPackageById(id);
        commit('SET_PACKAGE', data);
        return data;
      } catch (err) {
        commit('SET_PACKAGE', {});
      }
    },
    async [PackageActionKeys.UPDATE_PACKAGE_DETAILS](
      _,
      { packageId, pack }: { packageId: number; pack: Record<string, unknown> }
    ) {
      await bff.updateSkyNodePackageById(packageId, pack);
    },
    async [PackageActionKeys.CREATE_PACKAGE](_, pack: IPackage) {
      return bff.createSkyNodePackage(pack);
    },
    async [PackageActionKeys.CREATE_PACKAGE_VERSION](
      _,
      {
        packageId,
        version,
        isLatest = false
      }: {
        packageId: number;
        version: CreatePackageVersionDTO;
        isLatest: boolean;
      }
    ) {
      return bff.createSkyNodePackageVersionById(packageId, version, isLatest);
    },
    async [PackageActionKeys.UPDATE_PACKAGE_VERSION](
      _,
      { packageId, versionId, version, isLatest = false }
    ) {
      return bff.updateSkyNodePackageVersionById(
        packageId,
        versionId,
        version,
        isLatest
      );
    },
    async [PackageActionKeys.FETCH_PRESIGNED_UPLOAD_URL]() {
      return bff.getPresignedUploadUrl();
    },
    async [PackageActionKeys.FETCH_PRESIGNED_DOWNLOAD_URL](
      _,
      { packageId, versionId }
    ) {
      return bff.getPresignedDownloadUrl(packageId, versionId);
    },
    async [PackageActionKeys.UPLOAD_OBJECT_TO_FILE_STORAGE](
      { commit }: { commit: any },
      { url, file }
    ) {
      const progressHandler = (progressEvent: ProgressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        commit('SET_PACKAGE_VERSION_UPLOAD_PROGRESS', percentage);
      };
      return bff.uploadObject(url, file, progressHandler);
    }
  } as ActionTree<PackageState, RootState>;
}

export { createActions, PackageActionKeys };
